import React, { useEffect } from 'react'
import { useParams } from "react-router"
import {
    Button,
    Grid,
    Hidden,
    useMediaQuery,
    Box as Container,
    SwipeableDrawer,
    CircularProgress
} from '@material-ui/core'
import { ArrowBack, Fullscreen, FullscreenExit, Add, Remove } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { GraveDataTable } from '../graveListView/GraveRow'
import { useTranslation } from 'react-i18next'
import { GraveData, GraveLocation } from '../../services/api'
import { useStateGrave } from '../common/useStateGrave'
import { useRecoilValue } from 'recoil'
import graveListAtom from '../../atoms/graveList'
import { trackEvent } from '../../services/applicationInsight'

const useStyles = makeStyles((theme) => ({
    topContainer: {
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.main,
            flex: '0',
            alignSelf: 'flex-start'
        },
    },
    mainContainer: {
        [theme.breakpoints.up('md')]: {
            flex: '12'
        },
    },
    infoSection: {
        backgroundColor: '#FFFFFF',
        marginTop: '0px',
        paddingBottom: '40px',
        paddingLeft: '0px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '20px',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: '500px',
            paddingLeft: '0px'
        }
    },
    infoSectionHeader: {
        color: '#000000',
        textAlign: 'left',
        padding: '20px 20px 0px 20px',
        marginBlockStart: '0px'
    },
    headerButtons: {
        color: '#FFFFFF',
        margin: '10px'
    },
    widthButton: {
        marginLeft: 'auto'
    },
    buttonTopBox: {
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            top: 'calc(64px + 70px)',
            width: '94%',
            left: '3%',
            backgroundColor: theme.palette.primary.main,
            zIndex: 1
        },
        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
            backgroundColor: theme.palette.primary.main,
            width: '100%',
            height: '54px',
            top: '0px',
            zIndex: 1
        },
    },
    buttonBottomBox: {
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            backgroundColor: theme.palette.primary.main,
            width: '100%',
            height: '54px',
            bottom: '0px',
            zIndex: 1
        }

    },
    iframeArea: {
        paddingBottom: '0px',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            top: 'calc(64px + 70px + 54px)',
            height: 'calc(100vh - 108px - 64px - 70px)',
            width: '94%',
            left: '3%',
            zIndex: 1
        },
        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
            top: '54px',
            height: 'calc(100vh - 108px)',
            left: '0px',
            width: '100%',
            zIndex: 1
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: '500px'
        }

    },
    buttonCloseBox: {
        border: 'none',
        borderRadius: '0px',
        justifyContent: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '54px',
            bottom: '0px',
        }
    },
    containerExternalButton: {
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'center'
    }
}))

interface MapViewViewParams {
    id: string
    name: string
}

export function MapViewView() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const [fullWidth, setFullWidth] = React.useState(false)
    const [expandDrawer, setExpandDrawer] = React.useState(false)
    const desktopSize = useMediaQuery(theme.breakpoints.up('md'))
    const { id } = useParams<MapViewViewParams>()

    const graveList = useRecoilValue(graveListAtom);




    const citynomadiUrl = process.env.REACT_APP_CITYNOMADI_URL
    const graveLocation = citynomadiUrl + '["' + id + '"]'

    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setExpandDrawer(open)
    }

    /**
     * If there is no search list, return to front page. This is the case if we come to the map page with direct url.
     */
    function goBack() {
        if (graveList.graves.length === 0) {
            history.push("/");
        } else {
            history.goBack();
        }

    }


    return (
        <>
            <Grid container className={classes.topContainer}>
                <Grid item xs={12}>
                    <Container display="flex" className={classes.buttonTopBox}>
                        <Button id='map-back-button'
                            className={classes.headerButtons}
                            startIcon={<ArrowBack />}
                            onClick={goBack}
                        >
                            {graveList.graves.length === 0 ? t('back-frontpage') : t('back-search-results')}
                        </Button>
                        {desktopSize &&
                            <Button id='full-screen-button'
                                className={`${classes.headerButtons} ${classes.widthButton}`}
                                endIcon={fullWidth ? <FullscreenExit /> : <Fullscreen />}
                                onClick={() => setFullWidth(!fullWidth)}
                            >
                                {fullWidth ? t('show-grave-info') : t('full-screen-map')}
                            </Button>
                        }
                    </Container>
                </Grid>
            </Grid>
            <Grid container className={classes.mainContainer}>
                {desktopSize && !fullWidth &&
                    <Grid item md={4} >
                        <InfoSection />



                    </Grid>
                }
                <Grid item xs={12} md={fullWidth ? 12 : 8} className={classes.iframeArea}>
                    <iframe title="kartta" src={graveLocation} width="100%" height='100%' allow="geolocation"></iframe>
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={12}>
                        <Container display="flex" className={classes.buttonBottomBox}>
                            <Button id='map-back-button'
                                className={classes.headerButtons}
                                startIcon={<Add />}
                                onClick={toggleDrawer(true)}
                                aria-expanded={expandDrawer}
                            >
                                {t('show-grave-info')}
                            </Button>
                        </Container>
                        <SwipeableDrawer
                            anchor='bottom'
                            open={expandDrawer}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                        >
                            <Button id='map-back-button'
                                variant='contained'
                                aria-label={t('hide-grave-info')}
                                aria-expanded={expandDrawer}
                                color='primary'
                                className={classes.buttonCloseBox}
                                startIcon={<Remove />}
                                onClick={toggleDrawer(false)}
                            >
                                {t('hide-grave-info')}
                            </Button>
                            <InfoSection />
                        </SwipeableDrawer>
                    </Grid>
                </Hidden>
            </Grid>
        </>
    )
}

function InfoSection() {
    const classes = useStyles()

    // Either use the grave data provided by the state or load it from the cloud by parsing the url
    const grave = useStateGrave();


    useEffect(() => {
        if (grave.fullname) {
            document.title = `Hautahaku: ${grave.fullname}`;
        } else {
            document.title = "Hautahaku";
        }

        return () => {
            document.title = "Hautahaku";
        }
    }, [grave]);

    const [graveyard, area, block, row, graveId] = grave && grave.graveid ? grave.graveid.split('-') : ['', '', '', '', '']
    const graveLocation: GraveLocation = { graveyard, area, block, row, graveId }

    if (grave.graveid === "") {
        return (
            <div className={classes.infoSection} >
                <CircularProgress />
            </div>
        )
    }

    trackEvent("GraveMap");

    return (
        <div className={classes.infoSection} >
            <h2 className={classes.infoSectionHeader}>{grave.fullname}</h2>
            <GraveDataTable grave={grave} graveLocation={graveLocation} showMore={true} noLinks />
            
            <ExternalButton grave={grave} />
        </div>
    )
}

function ExternalButton({ grave }: { grave: GraveData }) {
    const { i18n } = useTranslation();
    const classes = useStyles();

    const btn = getButtonData(grave, i18n.language);

    function handleClick() {
        if(!btn) return;
        window.open(btn[0], '_blank');
    }

    if (btn === null) return <></>;

    return (
        <Container className={classes.containerExternalButton}>
            <Button variant='contained' color='primary' onClick={handleClick}>{btn[1]}</Button>
        </Container>

    );
}

function getButtonData(grave: GraveData, lang: string): [string, string] | null {
    if (lang === "fi" && grave.extBtnTxtFi && grave.extUrlFi) {
        return [grave.extUrlFi, grave.extBtnTxtFi];
    }

    if (lang === "sv" && grave.extBtnTxtSv && grave.extUrlSv) {
        return [grave.extUrlSv, grave.extBtnTxtSv];
    }

    if (lang === "en" && grave.extBtnTxtEn && grave.extUrlEn) {
        return [grave.extUrlEn, grave.extBtnTxtEn];
    }
    return null;

}